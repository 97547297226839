export default class HomeService {
    constructor() {
        if ($('.home').length > 0) {
            // Here you call up only the components intended for the home page (using code splitting).
            if (window.matchMedia('(min-width: 1001px)').matches) {
                import(
                    '@scripts/components/front-page/strate-groupe.component.js' /* webpackChunkName: "scripts/strate-groupe.component" */
                ).then(({ default: StrateGroupeComponent }) => {
                    new StrateGroupeComponent()
                })
            }

            import(
                '@scripts/components/front-page/slider-home.component.js' /* webpackChunkName: "scripts/slider-home.component" */
            ).then(({ default: SliderHomeComponent }) => {
                new SliderHomeComponent()
            })

            import(
                '@scripts/services/animation.service' /* webpackChunkName: "scripts/animation.service" */
            ).then(({ default: AnimationService }) => {
                new AnimationService()
            })
        }
    }
}
